* {
  box-sizing: border-box;
}

#root {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
  position: relative;
}

#root::before {
  content: "";
  position: absolute;
  height: 824px;
  transform: skewY(-12deg);
  top: -300px;
  left: 0;
  right: 0;
  z-index: 0;
  background-color: #f7fafc;
  overflow: hidden;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
