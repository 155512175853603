.calculator {
  max-width: 400px;
  margin: 0 auto;
  box-shadow: 0 15px 35px 0 rgba(60, 66, 87, 0.1),
    0 5px 15px 0 rgba(0, 0, 0, 0.07);
  position: relative;
  background: white;
  border-radius: 4px;
}

h1 {
  margin: 0;
  padding: 2rem;
}

.total {
  background-color: #e8eaf682;
  padding: 1rem;
  border-radius: 4px;
  color: #3f51b5;
}

.split-total {
  display: block;
  font-size: 2rem;
  font-weight: 600;
}

form {
  padding: 0 2rem 2rem;
}

.field {
  padding-bottom: 1rem;
}

label {
  display: block;
  text-align: left;
  font-weight: 600;
}

input {
  width: 100%;
  padding: 8px 16px;
  font-size: 16px;
  line-height: 28px;
  border: 1px solid #eee;
  border-radius: 4px;
}

.field-wrap {
  display: flex;
}

.increment {
  background: none;
  width: 3rem;
  font-size: 1.5rem;
  border: 1px solid #eee;
  background-color: #f3f4fb;
  color: #3f51b5;
  line-height: 1.2rem;
}

.increment.plus {
  border-radius: 4px 0 0 4px;
  margin-left: -1px;
}

.increment.minus {
  border-radius: 0 4px 4px 0;
  margin-right: -1px;
}

input {
  width: 100%;
  padding: 8px 16px;
  font-size: 16px;
  line-height: 28px;
  border: 1px solid #eee;
  border-radius: 0;
}

* {
  box-sizing: border-box;
}

#root {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
  position: relative;
}

#root::before {
  content: "";
  position: absolute;
  height: 824px;
  -webkit-transform: skewY(-12deg);
          transform: skewY(-12deg);
  top: -300px;
  left: 0;
  right: 0;
  z-index: 0;
  background-color: #f7fafc;
  overflow: hidden;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

