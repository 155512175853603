.calculator {
  max-width: 400px;
  margin: 0 auto;
  box-shadow: 0 15px 35px 0 rgba(60, 66, 87, 0.1),
    0 5px 15px 0 rgba(0, 0, 0, 0.07);
  position: relative;
  background: white;
  border-radius: 4px;
}

h1 {
  margin: 0;
  padding: 2rem;
}

.total {
  background-color: #e8eaf682;
  padding: 1rem;
  border-radius: 4px;
  color: #3f51b5;
}

.split-total {
  display: block;
  font-size: 2rem;
  font-weight: 600;
}
